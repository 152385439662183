import i18nStrings from '../i18n/i18n';

/*
 *  Load Default Language
 * Check if 'lang' GET parameter exist then return default languange
 * Check Browser Language: If it exists in i18n then use the Browser Language, else use default
 */

export const loadDefaultLanguage = () => {
	const DEFAULT_LANG = Object.keys(i18nStrings)[0];

	let queryStringParams = new URLSearchParams(window.location.search);
	let clientLang = queryStringParams.get("lang");

	if(clientLang){
		return Object.keys(i18nStrings).includes(clientLang)
			? clientLang 
			: DEFAULT_LANG;
	}

	const navLang = navigator.language.substring(0, 2);

	if (i18nStrings.hasOwnProperty(navLang))
		return navLang;

	return DEFAULT_LANG;
}