import { Auth } from 'aws-amplify';

export const handleIdPLogin = (identity_provider) => {
    // Store redirect_uri/authorization_code in local storage to be used to later
    let queryStringParams = new URLSearchParams(window.location.search);
    let redirect_uri = queryStringParams.get('redirect_uri');
    let authorization_code = queryStringParams.get('authorization_code');
    let clientState = queryStringParams.get('state');

    if (redirect_uri) {
        localStorage.setItem(`client-redirect-uri`, redirect_uri);
    }
    if (authorization_code) {
        localStorage.setItem(`authorization_code`, authorization_code);
    }
    if (clientState) {
        localStorage.setItem(`client-state`, clientState);
    }

    Auth.federatedSignIn({ provider: identity_provider });
}