import { React, useState } from 'react';
import { connect } from 'react-redux';

import { I18n } from '@aws-amplify/core';
import { AuthState } from '@aws-amplify/ui-components';
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { handleIdPLogin } from '../../utils/providerAuth';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { Translations } from '@aws-amplify/ui-components';
import brandLogo from '../../assets/Logos/brand-logo.png';
import { Branding } from '../../branding';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import Login from '../Login/Login';
import Register from '../Register/Register';
import RegisterConfirm from '../RegisterConfirm/RegisterConfirm';

const useStyles = makeStyles((theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing(3, 10)
	},
	logo: {
		width: '90px'
	},
	grid: {
		maxWidth: 460,
		margin: '0 auto',
		justifyContent: 'center'
	},
	cardTitle: {
		fontFamily: 'AkkoPro Medium',
		fontSize: '40px',
		fontWeight: '500',
		lineHeight: '40px',
		color: '#00547A',
		marginBottom: '30px'
	},
	cardDescription: {
		fontFamily: 'AkkoPro Light',
		fontSize: '15px',
		fontWeight: '300',
		lineHeight: '20px',
		textAlign: 'justify',
		color: Branding.black,
		marginBottom: '20px'
	},
	loginMethods: {
		width: '100%',
		padding: '0 16px',
		margin: '40px 0',
		fontFamily: 'AkkoPro Medium'
	},
	externalLinksContent: {
		display: 'flex',
		alignItems: 'center'
	},
	externalLink: {
		fontFamily: 'AkkoPro Medium',
		fontSize: '14px',
		fontWeight: '500',
		lineHeight: '24px',
		color: '#FF5000',
		textUnderlineOffset: '3px',
		padding: '8px 0',
		'&:hover': {
			cursor: 'pointer',
			opacity: Branding.opacityHover
		}
	},
	externalLinksSeparator: {
		borderRight: '1px solid #DADFE4',
		padding: '8px 14px 8px 0',
		marginRight: '14px'
	},
	helpText: {
		width: '100%',
		fontFamily: 'AkkoPro Medium',
		fontSize: '14px',
		fontWeight: '500',
		lineHeight: '24px',
		textAlign: 'center',
		color: '#00547A',
		borderTop: '1px solid #DADFE4',
		paddingTop: '15px',
		marginTop: '40px'
	},
	helpTel: {
		fontFamily: 'AkkoPro Medium',
		fontSize: '14px',
		fontWeight: '500',
		lineHeight: '24px',
		textAlign: 'center',
		color: '#2EA0BD',
		textDecoration: 'none',
		textUnderlineOffset: '3px',
		'&:hover': {
			cursor: 'pointer',
			opacity: Branding.opacityHover,
			textDecoration: 'underline'
		}
	}
}));

const mapStateToProps = (state) => {
	return {
		lang: state.app.lang
	};
};

const SignInContainer = ({ authState, loaded }) => {
	const classes = useStyles();
	const [showSignUpElements, setShowSignUpElements] = useState(true);

	const signUpOnClick = () => {
		try {
			const authenticatorElement = document.getElementsByTagName(
				'amplify-authenticator'
			);
			const signInElement =
				authenticatorElement[0].getElementsByTagName('amplify-sign-in');
			signInElement[0].shadowRoot.querySelectorAll('button')[2].click();

			setShowSignUpElements(false);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<div>
			{loaded && (
				<div className={classes.header}>
					<img src={brandLogo} className={classes.logo} />
					<LanguageSelect />
				</div>
			)}
			<Grid container className={classes.grid}>
				{showSignUpElements && (
					<>
						<Typography
							variant="body1"
							className={classes.cardTitle}
						>
							{I18n.get(Translations.SIGN_UP)}
						</Typography>
						<Typography
							variant="body1"
							className={classes.cardDescription}
						>
							{I18n.get('SIGNIN_CONTAINER_DESCRIPTION_1')}
							<strong>
								{I18n.get('SIGNIN_CONTAINER_DESCRIPTION_2')}
							</strong>
						</Typography>

						<Typography
							variant="body1"
							className={classes.cardDescription}
						>
							{I18n.get('SIGNIN_CONTAINER_DESCRIPTION_3')}
						</Typography>

						<Typography
							variant="body1"
							className={classes.cardDescription}
							style={{justifyContent: 'start'}}
						>
							{I18n.get('SIGNIN_CONTAINER_HELPER')}
							<Link
								href={I18n.get(
									'SIGNIN_CONTAINER_HELPER_LINK_HREF'
								)}
								target="_blank"
								className={`${classes.externalLink}`}
							>
								{I18n.get('SIGNIN_CONTAINER_HELPER_LINK')}
							</Link>
						</Typography>

						<div
							style={{
								display: 'block',
								width: '100%',
								marginBottom: '2rem',
								textAlign: 'right'
							}}
						>
							<button
								onClick={signUpOnClick}
								className="registrationButton"
							>
								{I18n.get(Translations.SIGN_UP).toUpperCase()}
							</button>
						</div>
					</>
				)}

				<Typography variant="body1" className={classes.cardTitle}>
					{I18n.get('SIGNIN_CONTAINER_TITLE')}
				</Typography>

				{!showSignUpElements && (
					<>
						<Typography
							variant="body1"
							className={classes.cardDescription}
						>
							{I18n.get('SIGNIN_CONTAINER_DESCRIPTION_1')}
							<strong>
								{I18n.get('SIGNIN_CONTAINER_DESCRIPTION_2')}
							</strong>
						</Typography>

						<Typography
							variant="body1"
							className={classes.cardDescription}
						>
							{I18n.get('SIGNIN_CONTAINER_DESCRIPTION_3')}
						</Typography>

						<Typography
							variant="body1"
							className={classes.cardDescription}
						>
							{I18n.get('SIGNIN_CONTAINER_HELPER')}
							<Link
								href={I18n.get(
									'SIGNIN_CONTAINER_HELPER_LINK_HREF'
								)}
								target="_blank"
								className={`${classes.externalLink}`}
							>
								{I18n.get('SIGNIN_CONTAINER_HELPER_LINK')}
							</Link>
						</Typography>
					</>
				)}

				{/*
				 * AmplifyAuthenticator Container
				 */}
				<AmplifyAuthenticator
					usernameAlias="email"
					style={{ justifyContent: 'center' }}
				>
					<ForgotPassword />
					<Login />
					<Register showSignUp={setShowSignUpElements} />
					<RegisterConfirm />
					<div>
						{I18n.get('LANDING_PAGE_WAIT_REDIRECTION')}
						<AmplifySignOut />
					</div>
				</AmplifyAuthenticator>

				<div className={classes.loginMethods}>
					{/*
					 * Login with Apple
					 */}
					{authState === AuthState.SignIn && (
						<Box>
							<button
								className="amazon btn"
								onClick={() =>
									handleIdPLogin('SignInWithApple')
								}
							>
								{I18n.get(
									'LANDING_PAGE_BUTTON_IDP_APPLE_SIGNIN_LABEL'
								)}
								<i className="fa fa-apple fa-fw" />
							</button>
						</Box>
					)}
					{/*
					 * Login with Google
					 */}
					{authState === AuthState.SignIn && (
						<Box>
							<button
								className="google btn"
								onClick={() => handleIdPLogin('Google')}
							>
								{I18n.get(
									'LANDING_PAGE_BUTTON_IDP_GOOGLE_SIGNIN_LABEL'
								)}
								<i className="fa fa-google fa-fw" />
							</button>
						</Box>
					)}
					{/*
					 * Login with Microsoft (used for Alperia's employees)
					 * 27/05/24 - 	Disabling this option for the go-live version of this portal. To be enabled later on when deemed necessary.
					 * 			 	In order to enable it, remove the 'false' boolean flag hereunder
					 */}
					{false && authState === AuthState.SignIn && (
						<Box>
							<button
								className="fb btn"
								onClick={() => handleIdPLogin('Azure')}
							>
								{I18n.get(
									'LANDING_PAGE_BUTTON_IDP_MICROSOFT_SIGNIN_LABEL'
								)}
								<i className="fa fa-windows fa-fw" />
							</button>
						</Box>
					)}
				</div>

				<Typography className={classes.externalLinksContent}>
					<Link
						href="https://clienti.fintelenergia.it/"
						className={`${classes.externalLink} ${classes.externalLinksSeparator}`}
					>
						{I18n.get('SIGNIN_CONTAINER_LOGIN_ALPERIA_FINTEL')}
					</Link>
					<Link
						href="https://tlr.alperia.eu/"
						className={classes.externalLink}
					>
						{I18n.get('SIGNIN_CONTAINER_LOGIN_TELERISCALDAMENTO')}
					</Link>
				</Typography>
				<Typography className={classes.helpText}>
					{I18n.get('SIGNIN_CONTAINER_LOGIN_HELP')}{' '}
					<a href="tel:800110055" className={classes.helpTel}>
						800 110 055
					</a>
				</Typography>
			</Grid>
		</div>
	);
};

export default connect(mapStateToProps)(SignInContainer);
