import { useEffect, useState } from "react"

export const useRedirectURI = () => {
    const [redirectURI, setRedirectURI] = useState('')

    useEffect(()=>{
        const queryStringParams = new URLSearchParams(window.location.search);
	    const redirectURI = queryStringParams.get('redirect_uri');
        setRedirectURI(redirectURI)
    })

    return { redirectURI }
}